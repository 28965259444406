import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container" style={{ margin: '20px' }}>
      <h2>Terms of Service</h2>
      <div className="tos-content">
        <p>Welcome to Refactored Esports. By accessing or using our services, you agree to be bound by the following terms and conditions (“Terms of Service”). These terms apply to all visitors, users, and others who access or use our services.</p>

        <h3>1. Acceptance of Terms</h3>
        <p>By accessing and using the services provided by Refactored Esports, you accept and agree to be bound by these terms and conditions. If you do not agree to all the terms, then you may not access the service.</p>

        <h3>2. Eligibility</h3>
        <p>By using Refactored Esports services, you represent that you are at least 13 years old. Users under the age of 18 must have permission from a parent or legal guardian to use our services.</p>

        <h3>3. Account Registration</h3>
        <p>To access certain features of our services, you may be required to create an account. You agree to provide accurate, current, and complete information during registration and keep your account updated. You are responsible for maintaining the confidentiality of your account credentials.</p>

        <h3>4. User Conduct</h3>
        <p>As a condition of use, you agree not to engage in any activity that is illegal or prohibited by these Terms of Service. This includes, but is not limited to, the following:</p>
        <ul>
          <li>Harassing or abusing other users.</li>
          <li>Using the service to upload, post, or distribute any content that is unlawful, defamatory, abusive, or otherwise objectionable.</li>
          <li>Impersonating another person or entity.</li>
          <li>Interfering with the normal operation of the service, including by introducing viruses or malware.</li>
        </ul>

        <h3>5. Content Ownership and License</h3>
        <p>All content, including but not limited to text, images, videos, and software, provided on the Refactored Esports platform is the property of Refactored Esports or its content creators. By submitting content, you grant Refactored Esports a worldwide, non-exclusive, royalty-free license to use, distribute, reproduce, modify, and publicly display your content for the purposes of operating the service.</p>

        <h3>6. Termination of Service</h3>
        <p>Refactored Esports reserves the right to suspend or terminate your account and access to the service at any time for any reason, including but not limited to a violation of these Terms of Service. Upon termination, you must cease all use of the service.</p>

        <h3>7. Third-Party Links</h3>
        <p>Our service may contain links to third-party websites or services that are not owned or controlled by Refactored Esports. We are not responsible for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Refactored Esports is not liable for any damage or loss caused by or in connection with the use of any such third-party content or services.</p>

        <h3>8. Limitation of Liability</h3>
        <p>In no event shall Refactored Esports, its directors, employees, or agents be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your access or use of our service. Your sole remedy for dissatisfaction with the service is to discontinue using the service.</p>

        <h3>9. Disclaimer of Warranties</h3>
        <p>The services are provided on an "as-is" and "as-available" basis. Refactored Esports makes no representations or warranties of any kind, express or implied, regarding the operation of the service or the information, content, or materials included on the service. You expressly agree that your use of the service is at your sole risk.</p>

        <h3>10. Governing Law</h3>
        <p>These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction in which Refactored Esports is located, without regard to its conflict of law provisions.</p>

        <h3>11. Changes to Terms of Service</h3>
        <p>We reserve the right to modify these Terms of Service at any time. If changes are made, we will notify you by updating the "Last Updated" date at the bottom of this page. Continued use of the service after any such changes shall constitute your consent to such changes.</p>

        <h3>12. Contact Information</h3>
        <p>If you have any questions about these Terms of Service, please contact us at admin@refactored.gg.</p>

        <p><strong>Last Updated:</strong> October 1, 2024</p>
      </div>
    </div>
  );
};

export default TermsOfService;
