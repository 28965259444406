import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profileData, setProfileData] = useState(null);
  // Fetch token and profile data from localStorage on page load
  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    const storedProfile = localStorage.getItem('profileData');

    if (storedToken) {
      if (isTokenExpired(storedToken)) {
        handleLogout();
      } else {
        const parsedUser = parseJwt(storedToken);
        setToken(storedToken);
        setUser(parsedUser.username);
        setIsAuthenticated(true);
        setProfileData(JSON.parse(storedProfile)); // Set the profile data from localStorage
      }
    }
  }, []);
  
  //Handle Register function
  const handleRegister = async (username, email, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });
  
      if (response.ok) {
        return { success: true };
      } else {
        const errorData = await response.json();
        return { success: false, message: errorData.message };
      }
    } catch (err) {
      console.error('Error during registration:', err);
      return { success: false, message: 'An error occurred during registration.' };
    }
  };  

  // Helper functions
  function parseJwt(token) {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  function isTokenExpired(token) {
    const parsedToken = parseJwt(token);
    if (!parsedToken) return true;

    const now = Math.floor(Date.now() / 1000);
    return parsedToken.exp < now;
  }

  // Save token and profile data to localStorage
  const saveTokenAndProfile = (token, profile) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('profileData', JSON.stringify(profile)); // Save profile to localStorage
    setToken(token);
    setProfileData(profile);
    setIsAuthenticated(true);
  };

  // Clear token and profile data on logout
  const clearToken = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('profileData'); // Clear profile data
    setToken(null);
    setUser(null);
    setProfileData(null); // Clear profile data in state
    setIsAuthenticated(false);
  };

  // Fetching user profile data
  const fetchProfileData = async () => {
    if (!token) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get_user_profile/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch profile data');
      }

      const data = await response.json();
      setProfileData(data);
      localStorage.setItem('profileData', JSON.stringify(data)); // Save profile data to localStorage
    } catch (err) {
      console.error('Error fetching profile data:', err);
    }
  };

  // Login function
  const handleLogin = async (email, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        return { success: false, message: errorData.message }; // Return error message from backend
      }
  
      const data = await response.json();
      saveTokenAndProfile(data.token, data.userProfile); // Save token and profile data
      setUser(data.userProfile.username); // Set the username
  
      return { success: true }; // Return success object on successful login
    } catch (err) {
      console.error('Login error:', err.message);
      return { success: false, message: 'An error occurred during login. Please try again.' };
    }
  };
  
  // Logout function
  const handleLogout = () => {
    clearToken(); // Clear token and profile data from localStorage
  };

  return (
    <AuthContext.Provider value={{
      token,
      user,
      profileData,
      isAuthenticated,
      handleLogin,
      handleLogout,
      fetchProfileData,
      fetchProfileData,
      handleRegister,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
