import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Button, Row, Col, Nav, Image, Navbar, Dropdown, Container, ListGroup } from 'react-bootstrap';
import { useAuth } from "../components/AuthContext"; // Import the useAuth hook
import LoginModal from '../components/Login';
import NotificationModal from '../components/Notifications'; // Import the NotificationModal

const NavBar = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <Nav className="align-items-center me-auto"> 
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>
    
                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View alls
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
  
          <Nav className="align-items-center">
            <Button variant="primary" onClick={handleShowLoginModal}>
              Login
            </Button>
            <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} />
          </Nav>
        </div>
      </Container>
    </Navbar>
  );  
  
};

const AuthNavBar = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isAuthenticated, handleLogout, token } = useAuth(); // Get the logout function and JWT token from AuthContext
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.is_read, true);
  const { fetchProfileData } = useAuth();

  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const handleShowNotificationModal = () => setShowNotificationModal(true);
  const handleCloseNotificationModal = () => setShowNotificationModal(false);

  const fetchNotifications = async () => {
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }

      const data = await response.json();
      setNotifications((prevNotifications) => {
        const prevIds = prevNotifications.map(n => n.id);
        const newIds = data.map(n => n.id);
        
        if (JSON.stringify(prevIds) !== JSON.stringify(newIds)) {
          return data;
        }
        return prevNotifications;
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchProfileData();
      fetchNotifications();

      const interval = setInterval(() => {
        fetchNotifications();
      }, 5000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [token, isAuthenticated]);

  // Mark all notifications as read
  const markNotificationsAsRead = () => {
    setNotifications((prevNotifications) => 
      prevNotifications.map(n => ({ ...n, is_read: true }))
    );
  };

  // Handle user logout
  const onLogout = () => {
    handleLogout(); // Call the logout function when the user clicks on "Logout"
  };

  // Notification item component
  const Notification = ({ link, sender, image, time, message, is_read = false }) => {
    const readClassName = is_read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  // Render loading state
  if (loading && isAuthenticated) {
    return <p>Loading notifications...</p>;
  }

  // Render error state
  if (error) {
    return <p>Error: {error}</p>;
  }

  // Render the navbar with notifications
  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          {/* Move the bell icon dropdown to the left */}
          <Nav className="align-items-center me-auto"> 
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>
  
                  {notifications.map(n => (
                    <Notification key={`notification-${n.id}`} {...n} />
                  ))}
                  <Dropdown.Item className="text-center text-primary fw-bold py-3" onClick={handleShowNotificationModal}>
                    <Button variant="primary" onClick={handleShowNotificationModal}>
                      View Notifications
                    </Button>
                  </Dropdown.Item>
                  <NotificationModal show={showNotificationModal} handleClose={handleCloseNotificationModal} />
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
  
          {/* Other items remain on the right side */}
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">Account</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
  
                <Dropdown.Divider />
  
                <Dropdown.Item className="fw-bold" onClick={onLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

const MainNavBar = () => {
  const { isAuthenticated, user, token } = useAuth(); // Get the logout function and JWT token from AuthContext

  if (isAuthenticated) {
    console.log("Logged in as: ", user);
    console.log("Token: ", token);
  }

  return (
    <div>
      {isAuthenticated ? (
        <AuthNavBar />
      ) : (
        <NavBar />
      )}
    </div>
  );
};

export default MainNavBar;