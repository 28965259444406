import React, { useEffect, useState } from "react";
import { Modal, Button, Tabs, Tab, ListGroup, Row, Col } from 'react-bootstrap';
import { useAuth } from "../components/AuthContext";
import { toast } from 'react-toastify';

const NotificationModal = ({ show, handleClose }) => {
  const { token } = useAuth();
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);

  // Fetch notifications (both unread and read)
  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }

      const data = await response.json();
      // Separate unread and read notifications
      const unread = data.filter(notification => !notification.is_read);
      const read = data.filter(notification => notification.is_read);
      setUnreadNotifications(unread);
      setReadNotifications(read);
    } catch (error) {
      toast.error('Failed to fetch notifications');
    } finally {
      console.log('Notifications fetched');
    }
  };

  useEffect(() => {
    if (show) {
      fetchNotifications(); // Fetch notifications when the modal is shown
    }
  // eslint-disable-next-line
  }, [show]);

  // Mark a notification as read
  const markAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/${notificationId}/read`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        // Remove the notification from the unread list and add it to the read list
        const updatedUnread = unreadNotifications.filter(n => n.id !== notificationId);
        const notificationToMark = unreadNotifications.find(n => n.id === notificationId);
        if (notificationToMark) {
          setUnreadNotifications(updatedUnread);
          setReadNotifications([notificationToMark, ...readNotifications]);
          toast.success('Notification marked as read.');
        }
      } else {
        throw new Error('Failed to mark notification as read');
      }
    } catch (error) {
      toast.error('Failed to mark notification as read');
    }
  };

  // Mark all unread notifications as read
  const markAllAsRead = async () => {
    try {
      const promises = unreadNotifications.map(notification => 
        fetch(`${process.env.REACT_APP_API_URL}/notifications/${notification.id}/read`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
      );
      await Promise.all(promises);
      setReadNotifications([...unreadNotifications, ...readNotifications]);
      setUnreadNotifications([]);
      toast.success('All notifications marked as read.');
    } catch (error) {
      toast.error('Failed to mark all notifications as read.');
    }
  };


  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Notifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="unread" id="notifications-tabs" className="mb-3" justify >
          {/* Unread Notifications Tab */}
          <Tab eventKey="unread" title={`Unread Notifications (${unreadNotifications.length})`}>
            {unreadNotifications.length > 0 ? (
              <>
                <Button variant="secondary" size="sm" className="mb-3" onClick={markAllAsRead}>
                  Mark All as Read
                </Button>
                <ListGroup>
                  {unreadNotifications.map((notification) => (
                    <ListGroup.Item key={notification.id} className="d-flex justify-content-between align-items-center">
                      <Row className="align-items-center">
                        <Col>
                          <p className="mb-0">{notification.message}</p>
                          <small>{notification.time}</small>
                        </Col>
                        <Col xs="auto">
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => markAsRead(notification.id)}
                          >
                            Mark as Read
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            ) : (
              <p>No unread notifications.</p>
            )}
          </Tab>

          {/* Read Notifications Tab */}
          <Tab eventKey="read" title={`Read Notifications (${readNotifications.length})`}>
            {readNotifications.length > 0 ? (
              <ListGroup>
                {readNotifications.map((notification) => (
                  <ListGroup.Item key={notification.id} className="d-flex justify-content-between align-items-center">
                    <Row>
                      <Col>
                        <p className="mb-0">{notification.message}</p>
                        <small>{notification.time}</small>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p>No read notifications.</p>
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationModal;
