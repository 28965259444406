import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MembersKillsList = () => {
  const [playersKills, setPlayersKills] = useState([]);

  useEffect(() => {
    const fetchPlayersKills = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/leaderboard/kills`);
        setPlayersKills(response.data);
      } catch (error) {
        console.error('Error fetching players kills:', error);
      }
    };

    fetchPlayersKills();
  }, []);

  return (
    <div className="kills-leaderboard-container" style={{ margin: '20px' }}>
      <h2>Overall Leaderboard (Top 100)</h2>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Player Name</th>
              <th>Total Kills</th>
            </tr>
          </thead>
          <tbody>
            {playersKills.map((player, index) => (
              <tr key={index}>
                <td>{player.player_name}</td>
                <td>{player.total_kills}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MembersKillsList;
