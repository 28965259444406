import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap'; // You can use your own styles or framework
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginModal from '../components/Login';

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState(null);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  // Function to extract token from hash URL
  const extractTokenFromHash = () => {
    const hash = window.location.hash;
    const token = new URLSearchParams(hash.replace('#/verify-email?', '')).get('token');
    return token;
  };

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/verify-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        const result = await response.json();

        if (response.ok) {
          setSuccess(true);
          toast.success('Email verified successfully! You can now log in.');
        } else {
          toast.error(result.message || 'Failed to verify email.');
        }
      } catch (err) {
        console.error('Error during verification:', err);
        toast.error('An error occurred during email verification.');
      } finally {
        setLoading(false);
      }
    };

    const extractedToken = extractTokenFromHash();
    
    setToken(extractedToken);

    if (extractedToken) {
      verifyEmail();
    } else {
      toast.error('Invalid or missing token.');
      setLoading(false);
    }
  }, [token]);

  return (
    <div className="email-verification-page">
      <ToastContainer />
      <div className="verification-container">
        {loading ? (
          <div className="loading-state">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Verifying...</span>
            </Spinner>
            <p>Verifying your email, please wait...</p>
          </div>
        ) : success ? (
          <div className="success-state">
            <h3>Your email has been verified!</h3>
            <Button variant="primary" onClick={handleShowLoginModal}>
              Login
            </Button>
            <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} />
          </div>
        ) : (
          <div className="failure-state">
            <h3>Email verification failed.</h3>
            <p>Please try the verification link again or contact support.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
