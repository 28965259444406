import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";


import "./scss/volt.scss";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

import { AuthProvider } from './components/AuthContext';

ReactDOM.render(
  <AuthProvider>
    <HashRouter>
      <ScrollToTop />
      <HomePage />
    </HashRouter>
  </AuthProvider>,
  document.getElementById("root")
);
