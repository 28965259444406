import React, { useState, useEffect } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { useAuth } from "../components/AuthContext";
import BronzeTicketImage from "../assets/img/bronze-ticket.png";
import GoldTicketImage from "../assets/img/gold-ticket.png";
import DiamondTicketImage from "../assets/img/diamond-ticket.png";
import { Modal, Form } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom'; // For extracting the query parameter

const getEntryFeeByTier = (tier, tickets) => {
  if (tickets < 1) {
    return "FREE";
  } else {
    switch (tier) {
      case 'Copper':
        return "1 Copper Ticket";
      case 'Bronze':
        return "1 Bronze Ticket";
      case 'Silver':
        return "1 Silver Ticket";
      case 'Gold':
        return "1 Gold Ticket";
      case 'Diamond':
        return "1 Diamond Ticket";
      case 'Platinum':
        return "1 Platinum Ticket";
      default:
        return '';
    }
  }
};

const selectImageByTier = (tier) => {
  switch (tier) {
    case 'Bronze':
      return BronzeTicketImage;
    case 'Gold':
      return GoldTicketImage;
    case 'Diamond':
      return DiamondTicketImage;
    default:
      return ''; // Default image or keep it empty
  }
};

const RulesModal = ({ show, onHide, rules, tournament }) => {
  const { isAuthenticated, token } = useAuth();

  const applyToTournament = async (tournamentId) => {
    if (!isAuthenticated) {
      toast.error('You must register or log in to apply to a tournament');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/apply/${tournamentId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tournamentId }),
      });

      if (response.ok) {
        toast.success('Applied successfully');
      } else {
        const error = await response.json();
        toast.error(error.message);
      }
    } catch (err) {
      toast.error('An error occurred while applying');
    }
  };

  const handleCopyLink = () => {
    const rulesUrl = `${window.location.origin}/#/tournaments?id=${tournament.id}`;
    navigator.clipboard.writeText(rulesUrl)
      .then(() => {
        toast.success('Link copied to clipboard!');
      })
      .catch(() => {
        toast.error('Failed to copy the link.');
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await applyToTournament(tournament.id);
    onHide(); // Close the modal after applying
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-black">{tournament.title} Tournament Rules</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rules ? (
          <div dangerouslySetInnerHTML={{ __html: rules }} />
        ) : (
          <p>No rules specified for this tournament.</p>
        )}
        <hr />
        <Form>
          <Form.Group>
            <Form.Label>Entry Fee: {getEntryFeeByTier(tournament.tier, tournament.requiredTickets)}</Form.Label>
            <br />
            <Form.Group>
              Tournament Tier is {tournament.tier} <br />
            </Form.Group>
          </Form.Group>
          <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={handleCopyLink}>
            Copy Link
          </span>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const TournamentCard = ({ tournament, openRulesModal, clearOpenRulesModal }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [showRulesModal, setShowRulesModal] = useState(openRulesModal);

  useEffect(() => {
    setShowRulesModal(openRulesModal);
  }, [openRulesModal]);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(tournament.start_time * 1000) - +new Date();
      let timeLeft = '';
  
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
  
        timeLeft = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      }
  
      return timeLeft;
    };    
    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [tournament.start_time]);

  const imageUrl = selectImageByTier(tournament.tier);
  const entryFeeByTier = getEntryFeeByTier(tournament.tier, tournament.requiredTickets);
  const formattedStartTime = new Date(tournament.start_time * 1000).toLocaleString();
  //const { isAuthenticated, user } = useAuth();

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)',
    marginBottom: '.25rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: '#ffffff',
    border: '1px solid #ffffff',
  };

  const cardHeaderStyle = {
    boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)',
    marginBottom: '.25rem',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: '#02ffa8',
    backgroundColor: '#000',
  };

  const cardFooterStyle = {
    boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)',
    marginBottom: '.25rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: '#00b4ff',
    backgroundColor: '#000',
  };

  const handleRegisterClick = () => {
      setShowRulesModal(true); // Show rules and apply modal
  };

  const handleCloseRulesModal = () => {
    setShowRulesModal(false);
    clearOpenRulesModal(); // Clear the URL parameter
  };

  return (
    <Card className="content__title card-hover-effect text-center mb-4 shadow" style={cardStyle}>
      <Card.Img variant="top" src={tournament.img} style={{ height: '12%', objectFit: 'cover' }} />
      <Card.Header style={cardHeaderStyle}>
        {tournament.title}<br />{tournament.tier} {tournament.type}<br />{tournament.tournament_type}
      </Card.Header>
      <Card.Body className="text-center">
        <Card.Img variant="top" src={tournament.altimg} style={{ height: '25%', width: '25%' }} />
        <Card.Text className="content__title" style={{ color: "#00b4ff" }}>
          {formattedStartTime} <br />
          {timeLeft && `${timeLeft}`} {tournament.gameName} <br />
          Region: {tournament.region} <br />
          Platform: {tournament.platform} <br />
          Entry Fee: {entryFeeByTier} <br />
          Team Slots: {tournament.max_total_teams} <br />
          Teams Registered: {tournament.total_registered} <br />
          <Button variant="primary" onClick={handleRegisterClick}>View Rules & Apply</Button>
          <br />
        </Card.Text>
      </Card.Body>
      <RulesModal
        show={showRulesModal}
        onHide={handleCloseRulesModal}
        rules={tournament.rules}
        tournament={tournament}
      />
      <Card.Footer className="" style={cardFooterStyle}>{tournament.prize_pool} Prize</Card.Footer>
    </Card>
  );
};

const TournamentsList = ({ tournaments, openRulesForTournament, clearOpenRulesModal }) => {
  return (
    <Container>
      <Row>
        {tournaments.map((tournament) => (
          <Col key={tournament.id} md={4}>
            <TournamentCard
              tournament={tournament}
              openRulesModal={openRulesForTournament === tournament.id}
              clearOpenRulesModal={clearOpenRulesModal}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export const Tournaments = () => {
  const [tournaments, setTournaments] = useState([]);
  const location = useLocation();
  const [openRulesForTournament, setOpenRulesForTournament] = useState(null);

  useEffect(() => {
    // Fetch active tournaments
    fetch(`${process.env.REACT_APP_API_URL}/get_active_tournaments`)
      .then((response) => response.json())
      .then((data) => {
        setTournaments(data);

        // Check URL parameters to see if 'id' is provided for rules modal
        const searchParams = new URLSearchParams(location.search);
        const tournamentId = searchParams.get('id');
        if (tournamentId) {
          setOpenRulesForTournament(parseInt(tournamentId, 10));
        }
      })
      .catch((error) => {
        console.error('Error fetching tournaments:', error);
      });
  }, [location]);

  const clearOpenRulesModal = () => {
    setOpenRulesForTournament(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('id');
    const newUrl = `${window.location.origin}${window.location.pathname}${window.location.hash.split('?')[0]}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  return (
    <div className="content-layout">
      <h1 id="page-title" className="ailerons brand-color-blue content__title">
        Current Tournaments
      </h1>
      <ToastContainer />
      <div className="content__body">
        <div id="page-description">
          <TournamentsList
            tournaments={tournaments}
            openRulesForTournament={openRulesForTournament}
            clearOpenRulesModal={clearOpenRulesModal}
          />
        </div>
      </div>
    </div>
  );
};

export default Tournaments;