import React, { useState } from 'react';

const Youtube = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [embedUrl, setEmbedUrl] = useState('');

  // Extract the video ID from the YouTube URL
  const getYouTubeVideoId = (url) => {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };

  // Handle URL input change and set the embed URL
  const handleInputChange = (e) => {
    const url = e.target.value;
    setVideoUrl(url);

    const videoId = getYouTubeVideoId(url);
    if (videoId) {
      setEmbedUrl(`https://www.youtube.com/embed/${videoId}`);
    } else {
      setEmbedUrl(''); // Reset if the URL is invalid
    }
  };

  return (
    <div className="youtube-embed-container">
      <input
        type="text"
        placeholder="Enter YouTube URL"
        value={videoUrl}
        onChange={handleInputChange}
        style={{ width: '100%', padding: '10px', marginBottom: '20px' }}
      />
      
      {embedUrl ? (
        <iframe
          width="560"
          height="315"
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded YouTube Video"
        ></iframe>
      ) : (
        videoUrl && <p>Invalid YouTube URL</p>
      )}
    </div>
  );
};

export default Youtube;
