import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap'; // Importing tabs from react-bootstrap

const Leaderboards = () => {
  const [tournaments, setTournaments] = useState([]); // List of tournaments
  const [selectedTournament, setSelectedTournament] = useState(null); // Selected tournament
  const [games, setGames] = useState([]); // List of games in the selected tournament
  const [gameStats, setGameStats] = useState({}); // Stats for individual games
  const [tournamentStats, setTournamentStats] = useState([]); // Overall tournament stats
  const [activeTab, setActiveTab] = useState('overall'); // Manage active tab

  // Fetch the list of tournaments on component mount
  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tournaments`);
        setTournaments(response.data);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
      }
    };

    fetchTournaments();
  }, []);

  // Fetch games when a tournament is selected
  useEffect(() => {
    if (!selectedTournament) return;

    const fetchGames = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tournaments/${selectedTournament}/games`);
        setGames(response.data);
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    fetchGames();
  }, [selectedTournament]);

  // Fetch overall tournament stats when a tournament is selected
  useEffect(() => {
    if (!selectedTournament) return;

    const fetchTournamentStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tournaments/${selectedTournament}/leaderboard`);
        setTournamentStats(response.data);
      } catch (error) {
        console.error('Error fetching tournament stats:', error);
      }
    };

    fetchTournamentStats();
  }, [selectedTournament]);

  // Fetch individual game stats when a game is selected
  const fetchGameStats = async (gameId) => {
    if (!gameStats[gameId]) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/games/${gameId}/leaderboard`);
        setGameStats((prevStats) => ({
          ...prevStats,
          [gameId]: response.data, // Store game-specific stats by gameId
        }));
      } catch (error) {
        console.error('Error fetching game stats:', error);
      }
    }
    setActiveTab(gameId); // Set the active tab to the selected game
  };

  return (
    <div className="leaderboard-container" style={{ margin: '20px' }}>
      <h2>Tournament Stats</h2>

      {/* Tournament selection */}
      <div className="tournament-selection" style={{ marginBottom: '20px' }}>
        <label>Select Tournament:</label>
        <select
          value={selectedTournament || ''}
          onChange={(e) => setSelectedTournament(e.target.value)}
        >
          <option value="" disabled>Select a tournament</option>
          {tournaments.map(tournament => (
            <option key={tournament.id} value={tournament.id}>
              {tournament.title}
            </option>
          ))}
        </select>
      </div>

      {/* Tabs for overall and individual game stats */}
      {games.length > 0 && (
        <Tabs
          activeKey={activeTab}
          onSelect={(tab) => setActiveTab(tab)} // Change the active tab
          className="mb-3"
        >
          {/* Overall Stats Tab */}
          <Tab eventKey="overall" title="Overall Stats">
            <div className="table-responsive"> {/* Added responsive class */}
              {tournamentStats.length > 0 ? (
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Placement</th>
                      <th>Player</th>
                      <th>Kills</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tournamentStats
                      .sort((a, b) => b.totalScore - a.totalScore) // Sort by total score in descending order
                      .map((player, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td> {/* Display the placement based on the index */}
                          <td>{player.player_name}</td>
                          <td>{player.totalKills}</td>
                          <td>{player.totalScore}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <p>No overall stats available.</p>
              )}
            </div>
          </Tab>

          {/* Dynamically generated game tabs */}
          {games.map((game) => (
            <Tab
              key={game.id}
              eventKey={game.id}
              title={`Game ${game.game_number}`}
              onEnter={() => fetchGameStats(game.id)} // Fetch stats when tab is entered
            >
              <div className="table-responsive"> {/* Added responsive class */}
                {gameStats[game.id] && gameStats[game.id].length > 0 ? (
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                      <th>Placement</th>
                      <th>Player</th>
                      <th>Kills</th>
                      <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gameStats[game.id]
                        .sort((a, b) => a.placement - b.placement) // Sort by placement in ascending order
                        .map((player, index) => (
                          <tr key={index}>
                            <td>{player.placement}</td>
                            <td>{player.player_name}</td>
                            <td>{player.kills}</td>
                            <td>{player.score}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No stats available for this game yet. </p>
                )}
              </div>
            </Tab>
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default Leaderboards;
